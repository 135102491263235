/* eslint-disable no-undefined */
import React from 'react';

export const ParamsContext = React.createContext();

const parseUrlWithParams = (urlWithParams) => {
  const parsedUrl = urlWithParams.substring(urlWithParams.indexOf('?') + 1);
  const params = new URLSearchParams(parsedUrl);
  params.delete('skip');
  params.delete('limit');
  return params.toString();
};

export const initialParamsState = {
  staff: '',
  assignmentManager: '',
  cancelledStaff: '',
  timesheets: '',
  meta: '',
};

export const paramsReducer = (state, action) => {
  const nextState = { ...state };
  console.log('action.payload', action.payload);

  switch (action.type) {
    case 'SET_STAFF_PARAMS':
      return {
        ...nextState,
        staff: parseUrlWithParams(action.payload.urlWithParams),
      };
    case 'SET_ASSIGNMENT_MANAGER_PARAMS':
      return {
        ...nextState,
        assignmentManager: parseUrlWithParams(action.payload.csvParams),
      };
    case 'SET_ASSIGNMENT_MANAGER_CANCELLED_STAFF_PARAMS':
      return {
        ...nextState,
        cancelledStaff: parseUrlWithParams(action.payload.csvParams),
      };
    case 'SET_STAFF_TIMESHEETS_PARAMS':
      return {
        ...nextState,
        timesheets: parseUrlWithParams(action.payload.csvParams), // need to see what this is
      };
    case 'SET_STAFF_TIMESHEETS_META_PARAMS':
      return {
        ...nextState,
        meta: action.payload, // need to see what this is
      };
    case 'SET_STAFF_PAYRUN_PARAMS':
      return {
        ...nextState,
        payrun: parseUrlWithParams(action.payload.csvParams), // need to see what this is
      };
    default:
      return state;
  }
};
