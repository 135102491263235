const noOutline = {
  field: {
    bg: 'grey.light',
    borderRadius: '3px',
    border: '0px solid',
    color: 'white',
    _placeholder: {
      color: 'white',
    },
    _invalid: {
      border: '1px solid',
      borderColor: 'red',
    },
  },
};

const mandatory = {
  field: {
    bg: 'grey.light',
    borderRadius: '3px',
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'white',
    _placeholder: {
      color: 'white',
    },
    _invalid: {
      border: '1px solid',
      borderColor: 'red',
    },
  },
};

const md = {
  field: {
    fontSize: 'size12',
    lineHeight: '15px',
    fontWeight: 'medium',
    fontFamily: 'medium',
    height: '34px',
  },
};

export const numberInputTheme = {
  variants: { noOutline, mandatory },
  sizes: { md },
  defaultProps: {
    size: 'md',
    variant: 'noOutline',
  },
};

export default numberInputTheme;
